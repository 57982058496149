<template>
  <div class="page-confirm" v-if="state.browser == 2 || state.browser == 1 && state.code !== ''">
    <vanTitle title="确认订单" go="-2" v-if="state.browser == '1'"></vanTitle>
    <vanTitle title="确认订单" v-else></vanTitle>
    <div class="address" @click="toAddress">
      <template v-if="state.address !== ''">
        <div class="lt">
          <div class="tit">{{state.address.format_city_area}}{{state.address.address}}</div>
          <div class="txt">{{state.address.name}} {{state.address.phone}}</div>
        </div>
        <div class="rt">
          <i class="icon-right"></i>
        </div>
      </template>

      <div class="empty" v-else>
        <i class="icon-add"></i>
        <span>添加地址</span>
      </div>
    </div>
    <div class="kech" v-if="state.course">
      <div class="lt">
        <img :src="state.course.thumb" class="img">
      </div>
      <div class="rt">
        <div class="title f-owt-two">{{state.course.title}}</div>
        <div class="price"><span>¥</span>{{state.course.price}}</div>
      </div>
    </div>
    <div class="bt-box" v-if="state.course">
      <div class="item">
        <div class="lt-tit">订单备注</div>
        <input type="text" placeholder="请输入订单备注" class="ipt" v-model="state.remark">
      </div>
      <div class="item" v-if="state.course.price > 0">
        <div class="lt-tit">支付方式</div>
        <div class="rt" v-if="state.browser == '1'">
          <i class="icon-pay icon-wx"></i>
          <span>微信支付</span>
        </div>
        <div class="rt" v-else>
          <i class="icon-pay icon-zfb"></i>
          <span>支付宝支付</span>
        </div>
      </div>
      <div class="item">
        <div class="lt-tit">听课手机号</div>
        <div class="rt">
          <i class="icon-pay icon-edit" @click="handleLessonEdit"></i>
          <input type="text" class="phone_ipt" :disabled="!isEditPhone" v-model="state.userMobile">

        </div>
      </div>
      <div class="item" v-if="isEditPhone">
        <div class="lt-tit">验证听课手机号</div>

        <input type="text" placeholder="请输入验证码" class="ipt" v-model="state.identCode" @change="editmobileValue">
      </div>
    </div>
    <div class="get-code" v-if="issendCodeSuccess" @click="handleSendCode">
      <span :class="[codeNum == 0 ? 'obtain-code':'obtain-code01']"> {{ codeNum == 0 ? "获取验证码" : `(${codeNum})后获取验证码` }}</span>

    </div>
    <div class="bt-protocol" @click="bindProtocol">
      <i class="icon-selected" v-if="state.is_protocol"></i>
      <i class="icon-unselected" v-else></i>
      <!-- 区分在线付费协议和教育付费协议 -->

      <div class="protocol" v-if="state.course.platform == '2' || state.course.platform == 8">已经阅读并同意<a href="//xue.huatu.com/service/zxAgre.html">《用户付费协议》</a></div>
      <div class="protocol" v-else>已经阅读并同意<a href="//xue.huatu.com/service/payAgre.html">《用户付费协议》</a></div>
    </div>
    <footer class="footer" v-if="state.course">
      <div class="foot">
        <div class="lt">
          <div class="price"><span>¥</span>{{state.course.price}}</div>
        </div>
        <div class="rt">
          <button class="confirm-btn" :disabled="verificationSuccess" :class="[verificationSuccess ? 'confirm-btn01':'confirm-btn']" @click="submit">
            立即购买
          </button>

        </div>
      </div>
    </footer>
    <van-popup v-model:show="state.show_code" round @click-overlay="bindCodePop" @opened="onOpened">
      <div class="pop-code">
        <div class="title">扫码支付</div>
        <div id="qrcode" ref="qrcode"></div>
        <div class="small-btn btn">支付成功</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { reactive, onMounted, getCurrentInstance, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { getStore, sensorTrack, setStore } from '../../utils/util'
import { useStore } from 'vuex'
import md5 from 'js-md5'
import QRCode from 'qrcodejs2' // 引入qrcode
export default {
  name: 'confirm',
  setup() {
    const { proxy } = getCurrentInstance()
    const $route = useRoute()
    const $router = useRouter()
    const store = useStore()
    const time = Date.parse(new Date())
    const isEditPhone = ref(false) //是否修改手机号
    const issendCodeSuccess = ref(false) //手机验证码是否成功
    const verificationSuccess = ref(false) //立即下单按钮是否可点
    const codeNum = ref(0)
    //
    let clearId = ref(0) //定时器id
    const state = reactive({
      address: '', //默认地址
      course: '', //课程信息
      id: '',
      address_id: '',
      shop_id: '',
      remark: '', //订单备注
      flag: '', //0-推广订单1-拼团订单
      code: '', //wxcode
      browser: '', //1-微信浏览器2-其他浏览器
      is_pc: '', //1-pc端2-移动端
      show_code: false, //code码弹窗
      qrCode: '', //二维码链接
      group_id: '', //团ID
      gu_user_id: '', //参团ID
      url: '', //页面来源
      is_protocol: false, //勾选协议
      ly: '',
      spread_url: '',
      userMobile: '', // 用户手机号
      mobile_msg: '', //手机号验证码
      identCode: '' //修改手机号验证码
    })

    onMounted(() => {
      //获取手机号，中间4位隐藏
      let userInfo = getStore('userInfo')
      if (userInfo) {
        state.userMobile =
          (userInfo.mobile + '').substr(0, 3) +
          '****' +
          (userInfo.mobile + '').substr(7)
      }
      proxy.$toast.clear()

      if ($route.query.id) {
        state.id = $route.query.id
        state.shop_id = $route.query.shop_id
        state.flag = $route.query.flag

        if ($route.query.url) {
          state.url = $route.query.url
        }

        getData() //获取数据
      }

      if ($route.query.group_id) {
        state.group_id = $route.query.group_id
      }
      if ($route.query.gu_user_id) {
        state.gu_user_id = $route.query.gu_user_id
      }
      if (store.state.globalData.ly && store.state.globalData.ly != '') {
        state.ly = store.state.globalData.ly
      }
      if (
        store.state.globalData.spread_url &&
        store.state.globalData.spread_url != ''
      ) {
        state.spread_url = store.state.globalData.spread_url
      }
      //验证码倒计时计时器清除
      clearInterval(clearId)
    })

    //判断是否是微信浏览器的函数
    const isWeiXin = () => {
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase()
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        state.browser = '1'

        // 获取code
        let code = getUrlParam('code')
        let local = window.location.href
        let php_time = time.toString().substring(0, 10)
        let secret = 'getWxCodeFor_purchase.huatu.com'
        let signNew = md5(md5(local + php_time + secret))
        let appid = ''

        // 区分在线和教育公众号appid
        if (
          state.course.platform == '2' ||
          (state.course.department == 3 && state.course.type != 5)
        ) {
          // appid = "wx42d6c48d12ecc5a0";
          appid = 'wxb6ffc17b892a2a7b'
        } else {
          appid = 'wxb6ffc17b892a2a7b'
        }

        if (code == null || code == '') {
          window.location.href =
            'https://m.xue.huatu.com/mp/proxy.php?appid=' +
            appid +
            '&time=' +
            php_time +
            '&sign=' +
            signNew +
            '&redirect_url=' +
            encodeURIComponent(local)
        } else {
          state.code = code
        }
      } else {
        state.browser = '2'
      }

      // 判断当前设备是移动端还是PC端
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      console.log('flag--------confirm', flag)
      if (flag) {
        state.is_pc = 2
      } else {
        state.is_pc = 1
      }
      console.log('state.is_pc ', state.is_pc)
    }

    // 获取url code参数
    const getUrlParam = (name) => {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      let url = window.location.href.split('#')[0]
      let search = url.split('?')[1]
      if (search) {
        var r = search.substr(0).match(reg)
        if (r !== null) return unescape(r[2])
        return null
      } else {
        return null
      }
    }

    // 获取数据
    const getData = () => {
      proxy.$http
        .get('/api/order/review', {
          params: {
            id: state.id,
            flag: state.flag
          }
        })
        .then((res) => {
          const body = res.data.data

          if (res.data.code == '200') {
            state.course = body.course

            isWeiXin() //是否为微信浏览器

            //获取选中地址ID
            let address_id = localStorage.getItem('address_id')
            if (address_id) {
              state.address_id = address_id
              getAddress() //获取地址信息
              localStorage.setItem('address_id', '')
            } else {
              if (body.address.address) {
                state.address = body.address
              }
            }
          } else {
            proxy.$toast(body.message)
          }
        })
        .catch((error) => console.log(error))
    }
    let submitStatus = false
    // 提交订单
    const submit = () => {
      if (!state.is_protocol) {
        proxy.$toast('请勾选付费协议')
        return false
      }
      if (state.course.need_post == 1 && state.address == '') {
        proxy.$toast('请填写地址')
        return false
      }
      if (submitStatus) {
        return
      }
      submitStatus = true
      let userInfo = getStore('userInfo')
      console.log('userInfo---提交', userInfo)
      // 提交订单成功后上报手机号
      sa.login(userInfo.mobile)
      sa.setOnceProfile({
        // huatu_id: userInfo.huatuId,
        huatu_code: userInfo.huatuCode,
        huatu_edu_union_id: userInfo.unionId
      })
      proxy.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...'
      })

      console.log(2222)
      let userMobile = ''
      if (isEditPhone.value && state.userMobile) {
        userMobile = state.userMobile
      } else {
        userMobile = userInfo.mobile
      }
      let data = {
        mobile: state.address.phone,
        join_class_mobile: userMobile,
        name: state.address.name,
        address: state.address
          ? state.address.format_city_area + ' ' + state.address.address
          : '',
        cid: state.id,
        shop_id: state.shop_id,
        flag: state.flag,
        remark: state.remark,
        code: state.code
        // browser: state.browser,
      }
      if (state.group_id !== '' && state.flag == '1') {
        data.group_id = state.group_id
      }
      if (state.gu_user_id !== '' && state.flag == '1') {
        data.gu_user_id = state.gu_user_id
      }
      if (state.ly !== '' && state.spread_url !== '') {
        // console.log('到这里了', state.ly, state.spread_url);
        data.ly = state.ly
        data.url = state.spread_url
      }

      // 支付方式 1 微信扫码, 2 微信公众号, 3 微信H5, 4 => 微信小程序, 5 支付宝扫码, 6 支付宝手机网站
      if (state.browser == 1) {
        // 微信支付
        if (state.is_pc == '2') {
          // 移动端支付
          data.pay_way = 2
        } else {
          // pc端支付
          data.pay_way = 1
        }
      } else {
        // 支付宝支付
        if (state.is_pc == '2') {
          // 移动端支付
          data.pay_way = 6
        } else {
          // pc端支付
          data.pay_way = 5
        }
      }

      proxy.$http
        .post('/api/order/submit', data, {
          timeout: 50000
        })
        .then((res) => {
          submitStatus = false
          const body = res.data.data
          proxy.$toast.clear()
          console.log('res.data进入提交---------', res.data)

          if (res.data.code == '200') {
            sTrackSubmit(body.order_id)
            if (state.course.price > 0) {
              //付费课程
              if (state.browser == 1) {
                //微信支付
                if (state.is_pc == '2') {
                  //移动端支付
                  WeixinJSBridge.invoke(
                    'getBrandWCPayRequest',
                    {
                      appId: body.appId, //公众号名称，由商户传入
                      timeStamp: body.timeStamp, //时间戳，自1970年以来的秒数
                      nonceStr: body.nonceStr, //随机串
                      package: body.package,
                      signType: body.signType, //微信签名方式：
                      paySign: body.paySign, //微信签名
                      jsApiList: ['chooseWXPay']
                    },
                    (res) => {
                      // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                      if (res.err_msg == 'get_brand_wcpay_request:ok') {
                        paymentTrack(1, body.order_id).then((res) => {
                          window.location.href = '/order'
                        })
                        // window.location.href = "/success"
                      } else {
                        paymentTrack(0, body.order_id).then((res) => {
                          toDetail(body.order_id)
                        })
                      }
                    }
                  )
                } else {
                  //pc端支付
                  state.show_code = true
                  state.qrCode = body.qrCode
                }
              } else {
                //支付宝支付
                if (state.is_pc == '2') {
                  //移动端支付
                  document.querySelector('body').innerHTML = body.qrBody //查找到当前页面的body，将后台返回的form替换掉body内容
                  document.forms[0].submit()
                } else {
                  //pc端支付
                  state.show_code = true
                  state.qrCode = body.qrCode
                }
              }
            } else {
              paymentTrack(1, body.order_id).then((res) => {
                if (state.browser == 1) {
                  $router.go(-2) //微信支付
                } else {
                  $router.go(-1) //支付宝
                }
              })
            }
          } else if (res.data.code == '100206') {
            sTrackSubmit(res.data.extra.order_id).then((res1) => {
              toDetail(res.data.extra.order_id)
            })
          } else {
            proxy.$toast(res.data.message)
            paymentTrack(0, '')
          }
        })
        .catch((error) => {
          console.log('error', error)
          // proxy.$toast(error.data.message);
          paymentTrack(0, '')
          submitStatus = false
        })
    }

    // 跳转订单详情页
    const toDetail = (id) => {
      $router.push({
        name: 'orderDetail',
        params: {
          id: id
        }
      })
    }

    // 跳转订单页
    const toOrder = () => {
      $router.replace({
        name: 'order'
      })
    }

    // 生成二维码
    const qrcode = (qrcode, text) => {
      $('#qrcode>img').remove()
      let qrcode2 = new QRCode(qrcode, {
        width: 200,
        height: 200, // 高度
        text: text // 二维码内容
      })
    }

    // 弹窗加载完成
    const onOpened = () => {
      qrcode('qrcode', state.qrCode)
    }

    // pc二维码弹窗
    const bindCodePop = () => {
      state.show_code = false
    }

    // 获取地址
    const getAddress = () => {
      proxy.$http
        .get('/api/address/info', {
          params: {
            id: state.address_id
          }
        })
        .then((res) => {
          console.log('地址===>', res.data)
          const body = res.data.data

          if (res.data.code == '200') {
            state.address = body
            state.address.format_city_area = body.cityarea
          } else {
            proxy.$toast(body.message)
          }
        })
        .catch((error) => console.log(error))
    }

    // 跳转地址列表
    const toAddress = () => {
      $router.push({
        path: '/address',
        query: {
          is_back: true
        }
      })
    }

    // 勾选协议
    const bindProtocol = () => {
      state.is_protocol = !state.is_protocol
    }

    // 神策监听submit事件
    const sTrackSubmit = (order_id) => {
      return sensorTrack({
        eventName: 'HuaTuEducation_m_htxd_OrdersSubmitted',
        params: {
          order_id: order_id,
          course_id: state.course.id,
          course_title: state.course.title,
          course_source: state.course.platform,
          teach_way: state.course.type,
          price: state.course.origin_price,
          real_price: state.course.price,
          buy_type: state.flag == 1 ? '拼团' : '普通',
          promoter_phone: '',
          ehr_account: '',
          $url: window.location.href,
          business_unit: state.course.department
        }
      })
    }

    // 支付成功与否的埋点
    const paymentTrack = (type, order_id) => {
      let eventName =
          type === 1
            ? 'HuaTuEducation_m_htxd_PaymentSucceed'
            : 'HuaTuEducation_m_htxd_PaymentFailed',
        params = {
          order_id: order_id,
          course_id: state.course.id,
          course_title: state.course.title,
          course_source: state.course.platform,
          teach_way: state.course.type,
          price: state.course.origin_price,
          real_price: state.course.price,
          promoter_phone: '',
          ehr_account: '',
          $url: window.location.href,
          business_unit: state.course.department
        }
      if (type === 1) {
        params.buy_type = state.flag == 1 ? '拼团' : '普通'
      }
      return sensorTrack({
        eventName,
        params
      })
    }
    //编辑听课手机号
    const handleLessonEdit = () => {
      isEditPhone.value = true
      issendCodeSuccess.value = true
      verificationSuccess.value = true
      state.userMobile = ''
    }
    //获取听课手机号验证码
    const handleSendCode = () => {
      if (codeNum.value > 0) return
      let userInfo = getStore('userInfo')
      let data = {
        mobile: state.userMobile
      }
      proxy.$http
        .post('/api/auth/send_listen_mobile_msg', data)
        .then((res) => {
          const body = res.data
          console.log('body', body)
          if (res.data.code == '200') {
            issendCodeSuccess.value = true
            verificationSuccess.value = true

            proxy.$toast('验证码已发送')
            codeNum.value = 60
            if (clearId) clearInterval(clearId)
            clearId = setInterval(() => {
              codeNum.value--
              if (codeNum.value == 0) {
                clearInterval(clearId)
              }
            }, 1000)
          } else {
            proxy.$toast(body.message)
          }
        })
        .catch((error) => console.log(error))
    }
    //输入完验证码校验手机号是否可以下单
    const editmobileValue = () => {
      if (state.identCode) {
        let data = {
          mobile: state.userMobile,
          code: state.identCode
        }
        proxy.$http
          .post('/api/auth/verify_listen_mobile_and_code', data)
          .then((res) => {
            const body = res.data
            console.log('body', body)
            if (res.data.code == '200') {
              proxy.$toast(body.message)
              issendCodeSuccess.value = false
              verificationSuccess.value = false
            } else {
              proxy.$toast(body.message)
            }
          })
          .catch((error) => console.log(error))
      } else {
        proxy.$toast('请输入验证码')
      }
    }
    return {
      state,
      getData,
      getAddress,
      submit,
      getUrlParam,
      qrcode,
      bindCodePop,
      onOpened,
      toAddress,
      bindProtocol,
      toDetail,
      toOrder,
      handleLessonEdit,
      isEditPhone,
      handleSendCode,
      editmobileValue,
      issendCodeSuccess,
      verificationSuccess,
      codeNum,
      clearId
    }
  },

  methods: {}
}
</script>

<style scoped lang="scss">
.page-confirm {
  .address {
    margin: 15px 15px 0 15px;
    min-height: 70px;
    background: #ffffff;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .empty {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      .icon-add {
        display: block;
        width: 18px;
        height: 18px;
        margin-right: 5px;
        background: url(../../assets/icons/icon-add.png) center/100%,
          100% no-repeat;
      }
      span {
        font-size: 17px;
        color: #333333;
        font-weight: bold;
        line-height: 24px;
      }
    }
    .tit {
      font-size: 15px;
      font-weight: bold;
      line-height: 21px;
      width: 298px;
    }
    .txt {
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      margin-top: 8px;
    }
    .icon-right {
      display: block;
      width: 14px;
      height: 14px;
      background: url(../../assets/icons/icon-right.png) center/100%,
        100% no-repeat;
    }
  }
  .kech {
    margin: 12px 15px 0 15px;
    background: #ffffff;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .img {
      width: 70px;
      height: 70px;
      background: linear-gradient(180deg, #ee9183 0%, #ffcab7 100%);
      border-radius: 4px;
    }
    .rt {
      height: 70px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding-left: 10px;
    }
  }
  .bt-box {
    background: #ffffff;
    border-radius: 8px;
    font-size: 13px;
    margin: 12px 15px 0 15px;
    .item {
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      .lt-tit {
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
      }

      .rt {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .icon-pay {
          display: block;
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
        .icon-wx {
          background: url(../../assets/image/pay/icon-wx.png) center/100%,
            100% no-repeat;
        }
        .icon-zfb {
          background: url(../../assets/image/pay/icon-zfb.png) center/100%,
            100% no-repeat;
        }
        .icon-edit {
          background: url(../../assets/image/pay/icon-edit.png) center/100%,
            100% no-repeat;
        }
      }
      .ipt {
        text-align: end;
      }
      .phone_ipt {
        width: 84px;
      }
    }
  }
  .bt-protocol {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    bottom: 62px;
    left: 0;
    right: 0;
    font-size: 12px;
    color: #333333;
    padding-left: 16px;
    .icon-selected {
      display: block;
      width: 14px;
      height: 14px;
      background: url(../../assets/icons/icon-selected.png) center/100%,
        100% no-repeat;
      margin-right: 4px;
    }
    .icon-unselected {
      display: block;
      width: 14px;
      height: 14px;
      background: url(../../assets/icons/icon-unselected.png) center/100%,
        100% no-repeat;
      margin-right: 4px;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    .foot {
      width: 100%;
      height: 58px;
      background: #ffffff;
      box-shadow: 0px -1px 4px 0px rgba(69, 69, 69, 0.05);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
    }
  }
  .pop-code {
    width: 350px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .title {
      padding: 15px 0 30px 0;
    }
    .btn {
      margin-top: 30px;
    }
  }
  .get-code {
    float: right;
    margin: 12px 16px 0 0;
    .obtain-code {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #fe5c32;
      line-height: 20px;
    }
    .obtain-code01 {
      opacity: 0.34;
    }
  }
}
</style>
